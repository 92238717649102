import { ListAbout } from 'constants/AboutList';
import cl from './AboutContainer.module.css';
import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { MeteorContainer } from 'UIComponents/Meteor/MeteorContainer';
import Typewriter from 'typewriter-effect';

export const AboutContainer = () => {
  const [isAnimation, setAnimation] = useState(false);
  const [isVisibleNumber, setIsVisibleNumber] = useState(false);
  const [isVisibleMinsk, setIsVisibleMinsk] = useState(false);
  return (
    <article className={cl.container}>
      <h3 className={cl.h3}>About</h3>
      <div id="about" className={cl.anchor}></div>
      <aside className={cl.wrapper}>
        {ListAbout.map((item, index) => (
          <div className={cl.wrappHeader} key={index}>
            <h5>{item.title}</h5>
            {item.title === 'downloads' && !isAnimation ? (
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => {
                  if (isVisible) {
                    setIsVisibleNumber(true);
                  }
                  return (
                    <div style={{ height: 80 }}>
                      {isVisibleNumber ? (
                        <CountUp onEnd={() => setAnimation(true)} start={0} end={100} duration={2} />
                      ) : null}
                      {item.value.slice(2)}
                    </div>
                  );
                }}
              </VisibilitySensor>
            ) : (
              <VisibilitySensor partialVisibility>
                {({ isVisible }) => {
                  if (isVisible) {
                    setIsVisibleMinsk(true);
                  }
                  return (
                    <div style={{ height: 80 }}>
                      {isVisibleMinsk && item.title === 'based' ? (
                        <Typewriter
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(`${item.value}`)
                              .callFunction(function (state) {
                                state.elements.cursor.style.display = 'none';
                              })
                              .pauseFor(15000)
                              .start();
                          }}
                        />
                      ) : (
                        item.value
                      )}
                    </div>
                  );
                }}
              </VisibilitySensor>
            )}
          </div>
        ))}
      </aside>
      <MeteorContainer />
    </article>
  );
};
