import React, {useState, useRef} from 'react';
import cl from './ContactContainer.module.css';
import emailjs, {init} from '@emailjs/browser';

export const ContactContainer = () => {
  const [styles, setStyles] = useState(false);
  const form = useRef();
  const [input, setInput] = useState('');
  const [textarea, setTextArea] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setStyles(true);

    setTimeout(() => {
      setStyles(false);
    }, 2000);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        init(process.env.REACT_APP_EMAILJS_USER_ID)
      )
      .then(
        () => {
          setInput('');
          setTextArea('');
        },
        () => {
          alert('Something wrong. Try it again later');
        }
      );
  };

  return (
    <article className={cl.container}>
      <h6>Contact Us</h6>
      <div id="contact" className={cl.anchor}></div>
      <form ref={form} className={cl.wrapp_container} onSubmit={sendEmail}>
        <input
          placeholder="Email"
          onChange={(e) => setInput(e.target.value)}
          value={input}
          required
          name="user_email"
          className={cl.name}
        />
        <div className={cl.flex}>
          <div>
            <div className={cl.wrapper}>
              <div className={cl.text}>
                <textarea
                  onChange={(e) => setTextArea(e.target.value)}
                  value={textarea}
                  required
                  placeholder="Type something..."
                  rows="4"
                  name="message"
                ></textarea>
              </div>
            </div>
            <p className={cl.subTitle}>
              By clicking Submit, you agree to provide Your information LTD "SKR GAMES" for
              processing.
            </p>
          </div>
          <button className={cl.btn}>
            <svg height="240" width="240">
              <circle
                className={styles ? `${cl.circleDec} ${cl.circle}` : cl.circle}
                cx="120"
                cy="120"
                r="119"
                stroke="#fff"
                strokeWidth="2"
                fillOpacity="0"
              />
            </svg>
            <span>Submit</span>
          </button>
        </div>
      </form>
    </article>
  );
};
