import {useWindowWidth} from 'hooks/useWindowWidth';
import React from 'react';
import {Parallax} from 'react-parallax';
import cl from './WeaponContainer.module.css';

export const WeaponContainer = () => {
  const res = useWindowWidth();
  return (
    <Parallax
      className={cl.parallax}
      style={{
        position: 'absolute',
        bottom: res.width > 1280 ? `-74px` : '47px',
        left: 0,
        height: 652,

        width: 300,
      }}
      renderLayer={(percentage) => {
        return (
          <div
            style={{
              position: 'absolute',
              left: percentage <= 1 ? `${-282 + percentage * 282}px` : 0,
              top: '-63px',
              width: 340,
              height: 655,
            }}
          >
            <div className={cl.weapon} />
          </div>
        );
      }}
    ></Parallax>
  );
};
