import React from 'react';
import {Parallax} from 'react-parallax';
import cl from './BombContainer.module.css';
import bomb from 'img/bomb.png';
import {useWindowWidth} from 'hooks/useWindowWidth';

export const BombContainer = ({loadImage, onLoad}) => {
  const res = useWindowWidth();
  const isMobile = res.width <= 600;
  const height = res.width <= 600 ? 0 : 550;

  return isMobile ? (
    <img className={onLoad ? cl.bomb : cl.load} onLoad={loadImage} src={bomb} alt="#" />
  ) : (
    <Parallax
      className={cl.parallax}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: 500,
        height,
      }}
      renderLayer={(percentage) => {
        return (
          <div
            style={{
              position: 'absolute',
              left: percentage < 1 ? 0 : `${250 - percentage * 250}px`,
              top: res.width < 1300 ? 110 : 25,
              width: 200,
              height: 300,
            }}
          >
            <img className={onLoad ? cl.bomb : cl.load} onLoad={loadImage} src={bomb} alt="#" />
          </div>
        );
      }}
    ></Parallax>
  );
};
