import React from 'react';
import {Link} from 'react-router-dom';
import cl from './FooterContainer.module.css';
export const FooterContainer = ({mobileGame, nav}) => {
  return (
    <footer className={cl.footer}>
      <div>
        <h3>{mobileGame}</h3>
      </div>
      <nav className={cl.nav}>
        {nav.map((item, index) => (
          <Link key={index} to={{pathname: `${item.link}`}} target="_blank">
            <h3>{item.title}</h3>
          </Link>
        ))}
      </nav>
    </footer>
  );
};
