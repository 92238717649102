import url1 from 'img/background.png';
import url2 from 'img/background2.png';
import url3 from 'img/background3.png';
import url4 from 'img/background4.png';
import url5 from 'img/background5.png';

const ListGames = [
  {
    title: 'Construction Set',
    rate: '4.4',
    url: url1,
    description:
      'Assemble a wide variety of brick buildings and scenes in this classic constructor game. Start as a baby builder and level your way up to increasingly complex construction sets.',
    urlAppStore: 'https://apps.apple.com/us/app/construction-set-toys-puzzle/id1544659730',
    urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.constructor.games',
  },
  {
    title: 'Army Defence!',
    rate: '4.5',
    url: url5,
    description:
      '🪖 ARE YOU READY TO SERVE AND PROTECT?\nIt’s a desperate mission that only the brave and resourceful will survive: Your base is under attack and the enemy are pressing in on all sides. Only you and your tiny squad of special forces operatives can save the day, so parachute in and prepare to defend using everything you can get your hands on in this all-action tower defense game.',
    urlAppStore: 'https://apps.apple.com/us/app/army-defence/id1644499551',
    urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.war.no',
  },
  {
    title: 'Evolution Merge',
    rate: '4.7',
    url: url4,
    urlAppStore: 'https://apps.apple.com/app/id1645052152',
    description:
      'Even the big starts small – single-cell is small in this case – and you’ll need to eat and eat 🍖 to prove your fit to clamber up the food chain.',
    urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.evolution.merge',
  },
 /* {
    title: 'Hyper Evolution',
    rate: '4.7',
    url: url2,
    urlAppStore: 'https://apps.apple.com/app/hyper-evolution/id1575192727',
    description:
      'Dive into the primordial soup and get eating in this beautiful and entertaining survival simulator where your goal is to climb the food chain one organism at a time.',
    urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.evolution.hyper',
  },
  {
    title: 'Car Master 3D',
    rate: '4.7',
    url: url3,
    urlAppStore: 'https://apps.apple.com/us/app/car-master-3d/id1508900366',
    description:
      'Have you ever dreamed of becoming a mechanic? Do you love watching shows about car restoration and repairs? Then it’s time for you to take a more active role and tune up.',
    urlGooglePlay: 'https://play.google.com/store/apps/details?id=com.supergame.ohmycar',
  },*/
];

export {ListGames};
