const ListAbout = [
  {
    title: 'based',
    value: 'Limassol',
  },
  {
    title: 'since',
    value: '2019',
  },
  {
    title: 'downloads',
    value: '100m+',
  },
];

export {ListAbout};
