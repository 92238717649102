import React from 'react';
import cl from './CrowContainer.module.css';
import crow from 'img/crow.png';

export const CrowContainer = () => {
  return (
    <div className={cl.container}>
      <div className={cl.background}></div>
      <img src={crow} alt="#" className={cl.crow} />
    </div>
  );
};
