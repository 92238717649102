import React, {useState} from 'react';
import cl from './HeaderContainer.module.css';
import {HashLink as Link} from 'react-router-hash-link';
import {Link as ScrollLink} from 'react-scroll';
import {useWindowWidth} from 'hooks/useWindowWidth';
export const HeaderContainer = ({mobileGame, nav, onLoad}) => {
  const [toogle, setToogle] = useState(true);
  const onToggle = () => {
    setToogle(!toogle);
    if (document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  };
  const res = useWindowWidth();
  return (
    <header className={onLoad ? cl.header : cl.load}>
      <Link to="/#" className={cl.decorationMobileGame}>
        {mobileGame}
      </Link>
      {res.width > 700 ? (
        <nav className={cl.nav}>
          {nav.map((item, index) => {
            return (
              <ScrollLink
                rel="nofollow"
                activeClass="active"
                className={cl.decoration}
                to={item.to}
                spy
                key={index}
                smooth="easeInOutQuint"
                duration={400}
                offset={-50}
              >
                {item.title}
              </ScrollLink>
            );
          })}
        </nav>
      ) : toogle ? (
        <div onClick={onToggle}>
          <div className={cl.toogle}></div>
        </div>
      ) : (
        <nav className={cl.flexMenu}>
          <div onClick={onToggle} className={cl.close}></div>
          {nav.map((item, index) => {
            return (
              <ScrollLink
                rel="nofollow"
                activeClass="active"
                className={cl.toogleDecoration}
                to={item.to}
                spy
                key={index}
                duration={400}
                offset={-20}
                smooth="easeInOutQuint"
                onClick={onToggle}
              >
                {item.title}
              </ScrollLink>
            );
          })}
        </nav>
      )}
    </header>
  );
};
