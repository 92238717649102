import React from 'react';
import {Parallax} from 'react-parallax';
import cl from './NloContainer.module.css';

export const NloContainer = () => {
  return (
    <Parallax
      className={cl.parallax}
      style={{
        position: 'absolute',
        top: 650,
        right: 0,
        height: 500,
        width: 250,
      }}
      renderLayer={(percentage) => {
        return (
          <div
            style={{
              position: 'absolute',
              right: percentage <= 1 ? `${-170 + percentage * 170}px` : 0,
              width: 340,
              height: 300,
            }}
          >
            <div className={cl.nlo} />
          </div>
        );
      }}
    ></Parallax>
  );
};
