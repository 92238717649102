import {useWindowWidth} from 'hooks/useWindowWidth';

import React, {useRef, useEffect} from 'react';

import {BombContainer} from 'UIComponents/Bomb/BombContainer';
import {BookmarkContainer} from 'UIComponents/Bookmark/BookmarkContainer';
import {PhoneContainer} from 'UIComponents/Phone/PhoneContainer';
import cl from './TitleContainer.module.css';

export const TitleContainer = ({setHeightDiv, loadImage, onLoad}) => {
  const res = useWindowWidth();
  const ref = useRef(null);

  useEffect(() => {
    setHeightDiv(ref.current.clientHeight);
  }, [res.width]);

  return (
    <main ref={ref}>
      <div className={cl.container}>
        {res.width >= 1024 ? (
          <h1 className={onLoad ? `${cl.h1} ` : `${cl.load}`}>Skr Games</h1>
        ) : (
          <h1 className={onLoad ? `${cl.h1}` : `${cl.loadSmallSize}`}>
            Skr
            <br /> <span className={cl.span}>Games</span>
          </h1>
        )}

        <BombContainer loadImage={loadImage} onLoad={onLoad} />
        <BookmarkContainer loadImage={loadImage} onLoad={onLoad} />
      </div>
      <PhoneContainer loadImage={loadImage} onLoad={onLoad} />
    </main>
  );
};
