import {AboutContainer} from 'components/AboutContainer/AboutContainer';
import {ContactContainer} from 'components/ConctactContainer/ContactContainer';
import {FooterContainer} from 'components/FooterContainer/FooterContainer';
import {GamesListContainer} from 'components/GamesListContainer/GamesListContainer';
import {HeaderContainer} from 'components/HeaderContainer/HeaderContainer';
import {TitleContainer} from 'components/TitleContainer/TitleContainer';
import cx from 'classnames';
import {Element} from 'react-scroll';

import React, {useState, useEffect} from 'react';
import cl from './MainContainer.module.css';

const MainContainer = () => {
  const [heightDiv, setHeightDiv] = useState(0);
  const [onLoad, setOnLoad] = useState(false);
  const [isLoadImages, setLoadImages] = useState([]);

  useEffect(() => {
    if (isLoadImages.length >= 1) {
      if (!('fonts' in document)) return;

      document.fonts.load('1em Skr Games').then(function () {
        setOnLoad(true);
      });
    }
  }, [isLoadImages.length]);

  const loadImage = () => {
    setLoadImages([...isLoadImages, true]);
  };

  const setHeight = (height) => {
    setHeightDiv(height);
  };
  return (
    <div>
      <div className={onLoad ? '' : cl.wrapper_container_load}>
        <div
          className={cx({
            [cl.container3]: true,
            [cl.wrapper_container_load]: !onLoad,
          })}
        >
          <HeaderContainer
            onLoad={onLoad}
            nav={[
              {
                title: 'our games',
                to: 'games',
              },
              {
                title: 'about',
                to: 'about',
              },
              {
                title: 'contact',
                to: 'contact',
              },
            ]}
          />

          <TitleContainer
            setHeightDiv={setHeight}
            heightDiv={heightDiv}
            loadImage={loadImage}
            isLoadImages={isLoadImages}
            onLoad={onLoad}
          />
        </div>

        {onLoad && (
          <div className={cl.container2}>
            <GamesListContainer onLoad={onLoad} />
            <Element>
              <AboutContainer />
            </Element>
            <Element>
              <ContactContainer />
            </Element>
            <FooterContainer
              mobileGame="skr games All rights reserved"
              nav={[
                {
                  title: 'linkedin',
                  link: 'https://www.linkedin.com/company/skrgames/',
                },
                {
                  title: 'instagram',
                  link: 'https://www.instagram.com/skrgames/',
                },
                {
                  title: 'tiktok',
                  link: 'https://www.tiktok.com/@skr_games',
                },
                {
                  title: 'linktree',
                  link: 'https://linktr.ee/skr_games',
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export {MainContainer};
