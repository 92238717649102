import React from 'react';
import {Element} from 'react-scroll';
import cl from './GamesListContainer.module.css';
import {ItemList} from './ItemList/ItemList';
import {ListGames} from 'constants/GameList';
import {NloContainer} from 'UIComponents/Nlo/NloContainer';
import {WeaponContainer} from 'UIComponents/Weapon/WeaponContainer';
import {CrowContainer} from 'UIComponents/Crow/CrowContainer';
import VisibilitySensor from 'react-visibility-sensor';

export const GamesListContainer = () => {
  function onChange(isVisible) {
    return isVisible;
  }
  return (
    <div className={cl.container}>
      <CrowContainer />

      <h2 className={cl.h2}>Our Games</h2>
      <div id="games" className={cl.anchor}></div>
      <VisibilitySensor onChange={onChange}>
        <div style={{height: '1px'}}></div>
      </VisibilitySensor>

      {ListGames.map((item, index) => (
        <ItemList
          rate={item.rate}
          title={item.title}
          description={item.description}
          url={item.url}
          key={index}
          urlAppStore={item.urlAppStore}
          urlGooglePlay={item.urlGooglePlay}
        />
      ))}
      <Element id="games">
        <NloContainer isVisible={onChange} />
      </Element>
      <WeaponContainer />
    </div>
  );
};
