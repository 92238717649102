import React from 'react';
import cl from './MeteorContainer.module.css';

export const MeteorContainer = () => {
  return (
    <div className={cl.container}>
      <div className={cl.background}></div>
      <div className={cl.meteor}></div>
    </div>
  );
};
