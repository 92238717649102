import React from 'react';
import {render, hydrate} from 'react-dom';
import Loadable from 'react-loadable';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import './index.css';
import {routes} from './router/index';

const Application = (
  <Router>
    <Switch>
      {routes.map(({path, Component}) => {
        return <Route path={path} component={Component} exact={true} key={path} />;
      })}
      <Redirect from="*" to="/" />
      <Redirect from="/:url*(/+)" to={'/'} />
    </Switch>
  </Router>
);

const root = document.querySelector('#root');

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
