import React from 'react';
import {ReactComponent as Star} from 'img/StarRate.svg';
import cl from './ItemList.module.css';
import Slide from 'react-reveal/Slide';
import AppStore from 'img/AppStore.svg';
import GooglePlay from 'img/arrowGooglePlay.png';
import {ReactComponent as GooglePlayText} from 'img/Vector-4.svg';
import {Link} from 'react-router-dom';

export const ItemList = ({rate, url, title, urlAppStore, urlGooglePlay, description}) => {
  return (
    <Slide bottom>
      <article className={cl.container}>
        <div
          className={cl.background}
          style={{
            backgroundImage: `url(${url})`,
          }}
        >
          <div className={cl.wrapRate}>
            <Star />
            <p>{rate}</p>
          </div>
        </div>
        <div className={cl.wrapper}>
          <h4>{title}</h4>
          <p>{description}</p>
          <div className={cl.wrapper_buttons}>
            <Link to={{pathname: urlAppStore}} target="_blank">
              <img src={AppStore} alt="" />
            </Link>
            <Link to={{pathname: urlGooglePlay}} target="_blank">
              <div className={cl.wrapper_googleplay}>
                <img src={GooglePlay} className={cl.arrowGooglePlay} alt="" />
                <GooglePlayText className={cl.textGooglePlay} />
              </div>
            </Link>
          </div>
        </div>
      </article>
    </Slide>
  );
};
