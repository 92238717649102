import React from 'react';
import cx from 'classnames';
import cl from './PhoneContainer.module.css';

import {ListVideo} from 'constants/VideoList';
const number = Math.floor(Math.random() * ListVideo.length);

export const PhoneContainer = ({onLoad, loadImage}) => {
  return (
    <div
      className={cx(cl.phone, {
        [cl.load]: !onLoad,
      })}
    >
      <div className={cl.persp}>
        <video
          playsInline
          loop
          muted
          type="video/mp4"
          className={cl.video}
          src={ListVideo[number]}
          autoPlay
        />
      </div>
    </div>
  );
};
