import React from 'react';
import cl from './BookmarkContainer.module.css';
import bookmark from 'img/bookmark-fav-dynamic-color.png';
import {Parallax} from 'react-parallax';
import {useWindowWidth} from 'hooks/useWindowWidth';

export const BookmarkContainer = ({onLoad, loadImage}) => {
  const res = useWindowWidth();
  const height = res.width <= 600 ? 0 : res.width <= 900 ? 650 : res.width <= 1440 ? 650 : 900;

  return (
    <Parallax
      className={cl.parallax}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 250,
        height,
      }}
      renderLayer={(percentage) => {
        return (
          <div
            style={{
              position: 'absolute',
              top: 230,
              right: percentage <= 1 ? 0 : `${250 - percentage * 250}px`,
              width: 250,
              height: 452,
            }}
          >
            <img
              className={onLoad ? cl.bookmark : cl.load}
              onLoad={loadImage}
              src={bookmark}
              alt="#"
            />
          </div>
        );
      }}
    ></Parallax>
  );
};
