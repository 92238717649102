import {ROUTES} from 'constants/routes';
import {MainContainer} from 'components/MainContainer/MainContainer';

const {MAIN_ROUTE} = ROUTES;
const routes = [
  {
    path: MAIN_ROUTE,
    Component: MainContainer,
  },
];

export {routes};
